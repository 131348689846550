/**
 * @name MainRoutes
 * @description
 * This component holds all of our "main" (read: Insights / JPP) routes. We split
 * these up because we've got a few client specific routes, coming in from different
 * sub-domains (walmart.jyve.com as an example), that we want to make sure aren't
 * being fed our full JPP routes.
 *
 */

import { ENV, ENV_PROD, VIEWS } from 'core/config';
import getDefaultBrandForUser from 'helpers/getDefaultBrandForUser';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import AuthService from 'services/auth';
import { AppState } from 'state/store';

import { SentryMonitoredRoute } from '../sentry';
import PrivateRoute from './PrivateRoute';

const HomePageRedirect = React.lazy(
  () => import('components/HomePageRedirect')
);
const ProdAPIInDevelopmentUsageWarning = React.lazy(
  () => import('components/ProdAPIInDevelopmentUsageWarning')
);
const AppPreload = React.lazy(() => import('containers/AppPreload'));
const BrandsContainer = React.lazy(() => import('containers/Brands'));
const ChangePasswordContainer = React.lazy(
  () => import('containers/ChangePassword')
);
const CompletionReport = React.lazy(
  () => import('containers/CompletionReport')
);
const ExportDataContainer = React.lazy(() => import('containers/ExportData'));
const ForgotPasswordContainer = React.lazy(
  () => import('containers/ForgotPassword')
);
const InsightsInterceptor = React.lazy(
  () => import('containers/InsightsInterceptor')
);
const LoginContainer = React.lazy(() => import('containers/Login'));
const LogoutContainer = React.lazy(() => import('containers/Logout'));
const ModeQualityReport = React.lazy(
  () => import('containers/ModeQualityReport')
);
const MyJyvesContainer = React.lazy(() => import('containers/MyJyves'));
const NoBackstockContainer = React.lazy(() => import('containers/NoBackstock'));
const NotesContainer = React.lazy(() => import('containers/Notes'));
const PlannedMaintenance503 = React.lazy(
  () => import('containers/PlannedMaintenance503')
);
const StockingReport = React.lazy(() => import('containers/StockingReport'));
const StoreVisitsReport = React.lazy(
  () => import('containers/StoreVisitsReport')
);
const StoresContainer = React.lazy(() => import('containers/Stores'));
const TaskListsContainer = React.lazy(() => import('containers/TaskLists'));
const TaskListDetails = React.lazy(
  () => import('containers/TaskLists/Details')
);
const OnboardingContainer = React.lazy(() => import('containers/Onboarding'));
const PaymentContainer = React.lazy(() => import('containers/Zeal'));

/**
 * @name HandleRootHomePageRequest
 * If user is not logged in, this will take them to login, otherwise it will
 * redirect to a PrivateRoute path that can help load
 */
const HandleRootHomePageRequest: React.FC = () => {
  if (AuthService.isAuthenticated()) {
    // only look for default brand if we really know they are already logged in
    return <Redirect to={VIEWS.defaultBrandForUser.path} />;
  }
  return <Redirect to={VIEWS.login.path} />;
};

/**
 * @name HandleRedirectToDefaultBrand
 * This will either redirect to the previously viewed brand (if applicable),
 * otherwise load first brand in their list
 */
const HandleRedirectToDefaultBrand: React.FC = () => {
  const brands = useSelector((state: AppState) => state.user.brands);
  const brandId = getDefaultBrandForUser(brands);
  return <Redirect to={`/brands/${brandId}`} />;
};

export const MainRoutes = () => {
  return (
    <>
      {ENV !== ENV_PROD ? <ProdAPIInDevelopmentUsageWarning /> : null}
      <Switch>
        <SentryMonitoredRoute
          exact
          path={VIEWS.logout.path}
          component={LogoutContainer}
        />
        <SentryMonitoredRoute
          exact
          path={VIEWS.login.path}
          component={LoginContainer}
        />
        <SentryMonitoredRoute
          exact
          path={VIEWS.forgotPassword.path}
          component={ForgotPasswordContainer}
        />
        <SentryMonitoredRoute
          exact
          path={VIEWS.changePassword.path}
          component={ChangePasswordContainer}
        />
        <SentryMonitoredRoute
          exact
          path={VIEWS.maintenance.path}
          component={PlannedMaintenance503}
        />
        <SentryMonitoredRoute
          exact
          path={VIEWS.preload.path}
          component={AppPreload}
        />
        <PrivateRoute
          exact
          path={VIEWS.brands.path}
          component={BrandsContainer}
          pageTitle="Brands"
        />
        <PrivateRoute
          exact
          path={VIEWS.brandHome.path}
          component={HomePageRedirect}
        />
        <PrivateRoute
          exact
          path={VIEWS.brandInsights.path}
          component={InsightsInterceptor}
          pageTitle="Insights"
        />
        <PrivateRoute
          exact
          path={VIEWS.brandExport.path}
          component={ExportDataContainer}
          pageTitle="Export Data"
        />
        <PrivateRoute
          path={VIEWS.brandCompletion.path}
          component={CompletionReport}
          backButtonPath={VIEWS.brandInsights.path}
          pageTitle="Jyves Completed"
          exact
        />
        <PrivateRoute
          path={VIEWS.brandStoreVisits.path}
          component={StoreVisitsReport}
          backButtonPath={VIEWS.brandInsights.path}
          pageTitle="Stores"
          exact
        />
        <PrivateRoute
          path={VIEWS.brandQuality.path}
          component={ModeQualityReport}
          backButtonPath={VIEWS.brandInsights.path}
          pageTitle="Reviewed by Quality Engine"
          exact
        />
        <PrivateRoute
          path={VIEWS.brandStocking.path}
          component={StockingReport}
          backButtonPath={VIEWS.brandInsights.path}
          pageTitle="Stocking"
          exact
        />
        <PrivateRoute
          path={VIEWS.brandBackstock.path}
          component={NoBackstockContainer}
          backButtonPath={VIEWS.brandInsights.path}
          pageTitle="Backstock Conditions"
          exact
        />

        <PrivateRoute
          path={VIEWS.brandMyJyves.path}
          component={MyJyvesContainer}
          pageTitle="My Jyves"
          hideAppBarOnScroll
        />
        <PrivateRoute
          path={VIEWS.brandStores.path}
          component={StoresContainer}
        />
        <PrivateRoute
          path={VIEWS.brandNotes.path}
          component={NotesContainer}
          pageTitle="Notes"
          exact
        />

        <PrivateRoute
          path={VIEWS.jobTemplates.path}
          component={TaskListsContainer}
          pageTitle="Task Lists"
          exact
        />
        <PrivateRoute
          path={VIEWS.jobTemplateDetails.path}
          component={TaskListDetails}
          backButtonPath={VIEWS.jobTemplates.path}
          pageTitle="Task List"
          exact
        />
        <PrivateRoute
          path={VIEWS.defaultBrandForUser.path}
          component={HandleRedirectToDefaultBrand}
          exact
        />
        <SentryMonitoredRoute
          path={VIEWS.home.path}
          component={HandleRootHomePageRequest}
          exact
        />
        <SentryMonitoredRoute
          path={VIEWS.onboarding.path}
          component={OnboardingContainer}
        />
        <SentryMonitoredRoute
          path={VIEWS.jyverPaymentSetup.path}
          component={PaymentContainer}
        />
        <SentryMonitoredRoute
          path=""
          component={() => <h1>Page Not Found</h1>}
        />
      </Switch>
    </>
  );
};
