export const ENV_LOCAL = 'local';
export const ENV_DEV = 'development';
export const ENV_STAGE = 'stage';
export const ENV_TEST_TAHOE = 'test_tahoe';
export const ENV_TEST_PINNACLES = 'test_pinnacles';
export const ENV_PRE_PROD = 'pre_prod';
export const ENV_PROD = 'production';
export const ENV = process.env.REACT_APP_ENV_BUILD || ENV_DEV;
export const EXPORT_IS_DEV = (a: string, b: string) =>
  ENV === ENV_DEV ? a : b;

export const CSRF_TOKEN_COOKIE_KEY = 'csrftoken';

export const ROLLBAR_DISABLED = process.env.REACT_APP_ROLLBAR_DISABLED || false;
export const ROLLBAR_ID = 'd3d2e4ddaa7743f5ae8b5320596c80c4';

export const API_DOMAINS: Record<string, string> = {
  [ENV_LOCAL]: process.env.REACT_APP_DEV_API_URL ?? 'http://localhost:8000/',
  [ENV_DEV]: process.env.REACT_APP_DEV_API_URL ?? 'http://localhost:8000/',
  [ENV_STAGE]: 'https://api.stage.jyve.com/v2/',
  [ENV_TEST_TAHOE]: 'https://api.test-tahoe.jyve.com/v2/',
  [ENV_TEST_PINNACLES]: 'https://api.test-pinnacles.jyve.com/v2/',
  [ENV_PRE_PROD]: 'https://api.pre-prod.jyve.com/v2/',
  [ENV_PROD]: 'https://api.jyve.com/',
};
export const API_DOMAIN = API_DOMAINS[ENV];

export const IMGIX_URLS: Record<string, string> = {
  [ENV_DEV]: 'https://jyve-local.s3.us-west-2.amazonaws.com/',
  // 'https://jyve-job-task-uploads-prod-uswest2.s3.us-west-2.amazonaws.com/',
  [ENV_STAGE]: 'https://jyve-local.s3.us-west-2.amazonaws.com/',
  // "https://jyve-local.s3.us-west-2.amazonaws.com/",
  [ENV_PROD]: 'https://jyve-local.s3.us-west-2.amazonaws.com/',
  // "https://jyve-local.s3.us-west-2.amazonaws.com/",
};
export const IMGIX_URL = IMGIX_URLS[ENV];

export const LAUNCH_DARKLY_IDS: Record<string, string> = {
  [ENV_LOCAL]: '5c898c6ab2fd181de3411ad1',
  [ENV_DEV]: '5c898c6ab2fd181de3411ad1',
  [ENV_TEST_TAHOE]: '5ccc75836ebc550823315dc6',
  [ENV_TEST_PINNACLES]: '5ccc75836ebc550823315dc6',
  [ENV_STAGE]: '5c8a981ef9078007a63f1215',
  [ENV_PROD]: '5c61ef662b9fde32a8b496cc',
};
export const LAUNCH_DARKLY_ID = LAUNCH_DARKLY_IDS[ENV];

export const REPORT_DATE_FORMAT = 'YYYY-MM-DD';
export const REPORT_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}$/;
export const REPORT_DATE_GROUP_VALUES = ['day', 'week', 'month'];

export const API = {
  login: 'auth/login/?include_insights_user=true&jwt_auth=true',
  sendPhoneVerification: 'auth/send-mobile-verification/',
  verifyPhone: 'auth/verify-mobile/?include_insights_user=true&jwt_auth=true',
  verifyStoreNumber: 'auth/verify-store-number/',
  logout: 'jwt-token/delete/',
  tokenRefresh: 'jwt-token/refresh/',
  accountMe: 'account/me',
  myAccountInfo: 'account/me/?include_insights_user=true',
  resetPassword: 'account/password/reset/',
  changePassword: 'account/password/reset/confirm/',
  storeVisits: 'insights/stores_visited',
  jyveRatings: 'insights/jyve_ratings',
  completionRate: 'insights/completion_rate',
  noBackstock: 'insights/backstock_available',
  stocking: 'insights/stocking',
  brandOverview: 'insights/brand_overview',
  exportData: 'insights/export',
  exportJobsData: 'insights/export-jobs/',
  stores: 'stores',
  notes: 'brand_notes',
  banners: 'store_chains',
  scheduleJobs: 'jobs/request-new',
  rescheduleJob: 'jobs/request-change-new',
  jobs: 'jobs',
  jobsGeo: 'jobs/geo',
  jobsStats: 'jobs/stats',
  modeReports: 'insights/mode',
  jobTemplates: 'job_templates',
  healthCheck: 'health/',
};

export type VIEW_ITEM = {
  path: string;
  title?: string;
  requiresInsightsBreadcrumb?: boolean;
};

export const VIEWS: { [key: string]: VIEW_ITEM } = {
  home: {
    path: '/',
  },
  login: {
    path: '/login',
  },
  onboarding: {
    path: '/onboarding',
  },
  logout: {
    path: '/logout',
  },
  preload: {
    path: '/preload',
  },
  defaultBrandForUser: {
    path: '/default',
  },
  forgotPassword: {
    path: '/forgot_password',
  },
  changePassword: {
    path: '/change_password',
  },
  maintenance: {
    path: '/maintenance',
    title: 'Maintenance',
  },
  brands: {
    path: '/brands',
    title: 'Brands',
  },
  brandHome: {
    path: '/brands/:brandId',
    title: 'Home',
  },
  brandMyJyves: {
    path: '/brands/:brandId/jobs',
    title: 'My Jyves',
  },
  brandStores: {
    path: '/brands/:brandId/stores',
    title: 'Stores',
  },
  brandNotes: {
    path: '/brands/:brandId/notes',
    title: 'Notes',
  },
  brandExport: {
    path: '/brands/:brandId/export',
    title: 'Export Data',
  },
  jobTemplates: {
    path: '/brands/:brandId/templates',
    title: 'Task Lists',
  },
  jobTemplateDetails: {
    path: '/brands/:brandId/templates/:templateId',
    title: 'Task List',
  },
  brandInsights: {
    path: '/brands/:brandId/insights',
    title: 'Insights',
  },
  brandCompletion: {
    path: '/brands/:brandId/completion',
    title: 'Jyves Completed',
    requiresInsightsBreadcrumb: true,
  },
  brandQuality: {
    path: '/brands/:brandId/quality',
    title: 'Reviewed by Quality Engine',
    requiresInsightsBreadcrumb: true,
  },
  brandBackstock: {
    path: '/brands/:brandId/no_backstock',
    title: 'Backstock Conditions',
    requiresInsightsBreadcrumb: true,
  },
  brandStocking: {
    path: '/brands/:brandId/stocking',
    title: 'Stocking Data',
    requiresInsightsBreadcrumb: true,
  },
  brandStoreVisits: {
    path: '/brands/:brandId/store_visits',
    title: 'Stores Serviced',
    requiresInsightsBreadcrumb: true,
  },
  jyverPaymentSetup: {
    path: '/auth',
    title: 'Payment Setup',
  },
} as const;

export const MODE_REPORTS = {
  quality: '1e917765f717',
  keheScorecard: 'd78534e14bb6',
};

export const FEATURE_FLAGS = {
  qaDebugEnabled: 'insights-qa-debug-enable',
  defaultToJyvesPage: 'jpp-default-to-jyves-page-enabled',
  createRepeatableJobDisabled: 'jpp-create-repeatable-job-disabled',
  zendeskHelpWidgetEnabled: 'zendesk-help-widget-enabled',
};

export const STORAGE_KEYS = {
  mostRecentlyViewedBrand: 'MOST_RECENTLY_VIEWED_BRAND',
  jwtPayload: 'jyve.k3Llemm0051-bn8f8942kjsdnm--38vnnjkl',
  filtersOpen: 'JYVES_PAGE_FILTERS_OPEN',
};

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN ?? '';
export const SENTRY_TRACE_SAMPLE_RATE = 1.0;
export const SENTRY_TRACE_PROPAGATION_TARGETS = [
  /^\//,
  /^https:\/\/r\.jyve\.com/,
  /^https:\/\/api\.jyve\.com\//,
];
export const SENTRY_REPLAY_SESSION_SAMPLE_RATE = 0.1;
export const SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE = 1.0;
